import './Home.css'

const Home = () => {
    return (
    <div class='Container'>
      <p class='Version-Control'>Version 3.2.21 Alpha - Last Updated: Jan 23, 2025 </p>
    </div>
    )
    ;
  };
  
  export default Home;
  